import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import VerifyEmailResults from "../../components/VerifyEmailResults/VerifyEmailResults";

function VerifyEmail() {
  let { verifySecret } = useParams();

  return (
    <div className="login-page">
      <div className="login-container">
        <Container>
          <Row>
            <Col>
              <VerifyEmailResults verifySecret={verifySecret} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/">Log into your account</Link>
            </Col>
            <Col>
              <Link to="/passwordReset">Need help logging in?</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default VerifyEmail;

import React from "react";
import UserIcon from "../UserIcon/UserIcon";
import Logo from "../../assets/images/quizlicious-small.jpg";

function Header() {
  return (
    <div className="d-flex align-items-center justify-content-between p-1 px-3 px-lg-5">
      <div>
        <img src={Logo} alt="Quizlicious" />
      </div>
      <div>
        <UserIcon />
      </div>
    </div>
  );
}

export default Header;

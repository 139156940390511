import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const userSlice = createSlice({
  name: "user",
  initialState: { JWT: null, name: null, id: null, isAuthenticated: false },
  reducers: {
    setJWT(state, action) {
      if (action.payload !== null) {
        state.JWT = action.payload;
        const accessTokenDecoded = jwt_decode(action.payload.access);
        state.name = accessTokenDecoded.name;
        state.id = accessTokenDecoded.user_id;
        state.isAuthenticated = true;
      }
    },
    logout(state) {
      state.JWT = null;
      state.name = null;
      state.isAuthenticated = false;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;

import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import LoginForm from "../../components/Auth/LoginForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/quizlicious-medium.jpg";

function Login() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userName = useSelector((state) => state.user.name);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/topics");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="login-page site-bg">
      <div className="login-container">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <img src={Logo} alt="Quizlicious" />
            </Col>
          </Row>
          <LoginForm />
          <Row>
            <Col>
              <Link to="/signup" className="text-light">
                Sign up for an account!
              </Link>
            </Col>
            <Col>
              <Link to="/passwordReset" className="text-light">
                Need help logging in?
              </Link>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-center">
              <Link to="/legal" className="text-light">
                The Legal Stuff
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Login;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AddQuestionForm from "./AddQuestionForm";

function TopicQuestionsDisplay({ topic, questions, setQuestions }) {
  console.log("Received questions:");
  console.log(questions);
  const [addQuestionIsOpen, setAddQuestionIsOpen] = useState(false);

  const addQuestionHandleClose = () => {
    setAddQuestionIsOpen(false);
  };

  const handleAddQuestion = () => {
    setAddQuestionIsOpen(true);
  };

  console.log("topic.user_contributions=" + topic.user_contributions);

  return (
    <>
      <Modal show={addQuestionIsOpen} onHide={addQuestionHandleClose}>
        <Modal.Body>
          <AddQuestionForm
            topic={topic}
            onCancel={addQuestionHandleClose}
            setQuestions={setQuestions}
          />
        </Modal.Body>
      </Modal>
      <div className="topic-questions m-2 d-flex flex-column">
        <div>
          <h1>Questions</h1>
        </div>
        <div>
          {topic.user_contributions ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleAddQuestion()}
            >
              Add A Question
            </button>
          ) : null}
        </div>
        <div className="topic-questions-list position-relative">
          {questions.map((question, index) => (
            <div key={index} className="position-relative">
              {question.question}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TopicQuestionsDisplay;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TOPIC_ENDPOINT } from "../../constants/urls";
import axios from "axios";
import TopicsNavButtons from "../../components/Topics/TopicsNavButtons";
import TopicsDisplay from "../../components/Topics/TopicsDisplay";
import TopicQuestionsDisplay from "../../components/Topics/TopicQuestionsDisplay";
import TopicsTestButtons from "../../components/Topics/TopicsTestButtons";

function Topics() {
  let { topicId } = useParams();

  const [topics, setTopics] = useState({
    loaded: false,
    topics: [],
  });
  const [questions, setQuestions] = useState({ loaded: false, questions: [] });

  const navigate = useNavigate();

  useEffect(() => {
    var topicUrl = TOPIC_ENDPOINT;
    var questionUrl = TOPIC_ENDPOINT;
    if (topicId !== undefined && topicId !== null) {
      topicUrl = TOPIC_ENDPOINT + topicId + "/";
      questionUrl = TOPIC_ENDPOINT + topicId + "/questions/";
      axios
        .get(questionUrl, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
          },
        })
        .then((response) => {
          console.log("Got response from " + questionUrl);
          console.log(response.data);
          setQuestions({
            loaded: true,
            questions: response.data,
          });
        })
        .catch((error) => {});
    }

    axios
      .get(topicUrl, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
        },
      })
      .then((response) => {
        setTopics({
          loaded: true,
          topics: response.data,
        });
      })
      .catch((error) => {
        console.log("Error loading topics, navigating to /topics/");
        navigate(`/topics/`);
      });
  }, [topicId]);

  const handleGoToTopic = (newTopicId) => {
    if (newTopicId) {
      navigate(`/topics/${newTopicId}`);
    } else {
      navigate(`/topics/`);
    }
  };

  if (topics.loaded) {
    return (
      <div className="px-4">
        <div>
          {topics.topics.name !== null ? (
            <h1>{topics.topics.name}</h1>
          ) : (
            <h1>Topics</h1>
          )}
        </div>
        <div className="topic-nav-buttons">
          <TopicsNavButtons
            topic={topics.topics}
            handleGoToTopic={handleGoToTopic}
          />
        </div>
        <div>
          <TopicsTestButtons topic={topics.topics} navigate={navigate} />
        </div>
        <div className="d-flex">
          <TopicsDisplay
            topic={topics.topics}
            handleGoToTopic={handleGoToTopic}
            setTopics={setTopics}
          />
          {questions.loaded ? (
            <TopicQuestionsDisplay
              topic={topics.topics}
              questions={questions.questions}
              setQuestions={setQuestions}
            />
          ) : null}
        </div>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}

export default Topics;

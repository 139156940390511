import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import SignUpForm from "../../components/Auth/SignUpForm";
import Logo from "../../assets/images/quizlicious-medium.jpg";

function SignUp() {
  return (
    <div className="login-page site-bg">
      <div className="login-container">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <img src={Logo} alt="Quizlicious" />
            </Col>
          </Row>
          <SignUpForm />
          <Row>
            <Col>
              <Link to="/" className="text-light">
                Already have an account? Login here!
              </Link>
            </Col>
            <Col>
              <Link to="/passwordReset" className="text-light">
                Need help logging in?
              </Link>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="text-center">
              <Link to="/legal" className="text-light">
                The Legal Stuff
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SignUp;

import React from "react";

function ListTests({ tests, navigate }) {
  const startTestButton = () => {
    return (
      <div>
        <button className="btn btn-primary">Start Test</button>
      </div>
    );
  };

  const resumeTestButton = (values) => {
    console.log("Values passed to resumeTestButton");
    console.log(values);
    return (
      <div>
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate(`/test/${values}`);
          }}
        >
          Resume Test
        </button>
      </div>
    );
  };

  if (tests === undefined || tests === null) {
    return <div>Loading</div>;
  } else {
    return (
      <div className="container">
        {tests.map((test, index) => {
          return (
            <div key={index} className="row m-1">
              <div className="col">Test: {test.id}</div>
              <div className="col">
                Status:{" "}
                {test.start === null
                  ? "Not started"
                  : test.end === null
                  ? "In Progress"
                  : "Completed"}
              </div>
              <div className="col">
                {test.start === null
                  ? startTestButton(test.id)
                  : test.end === null
                  ? resumeTestButton(test.id)
                  : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ListTests;

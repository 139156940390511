import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { LOGIN_ENDPOINT } from "../../constants/urls";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";

function LoginForm() {
  const loginValidation = yup.object({
    email: yup.string().email(),
    password: yup.string(),
  });

  const [loginSubmission, setLoginSubmission] = useState(0);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    axios
      .post(LOGIN_ENDPOINT, values)
      .then((response) => {
        setLoginSubmission(1);
        toast("Successful login");
        dispatch(userActions.setJWT(response.data));
        localStorage.setItem("JWT", JSON.stringify(response.data));
      })
      .catch((error) => {
        setLoginSubmission(-1);
        toast("Unable to login");
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const submitDisabled = () => {
    if (formik.values.email && formik.values.password && formik.isValid) {
      return false;
    }

    return true;
  };

  if (loginSubmission === 1) {
    return <div></div>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Container>
        <Row>
          <Col className="d-inline-flex justify-content-center">
            <input
              className="form-control m-1"
              type="text"
              placeholder="Email"
              aria-label="email"
              {...formik.getFieldProps("email")}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-inline-flex justify-content-center">
            <input
              className="form-control m-1"
              type="password"
              placeholder="Password"
              aria-label="password"
              {...formik.getFieldProps("password")}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center ">
          <Col className="d-inline-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-info m-1"
              aria-label="login"
              disabled={submitDisabled()}
            >
              Login
            </button>
          </Col>
        </Row>
      </Container>
    </form>
  );
}

export default LoginForm;

import React from "react";
import { ACHIEVEMENT_IMAGE_URL } from "../../constants/urls";

function AchievementDetails({ details }) {
  return (
    <div className="row">
      <div className="col">
        <img
          src={
            details.count > 0
              ? ACHIEVEMENT_IMAGE_URL + details.filename + ".awarded.png"
              : ACHIEVEMENT_IMAGE_URL + details.filename + ".png"
          }
          alt={details.name}
        />
      </div>
      <div className="col">
        <div>
          <u>{details.name}</u>
        </div>
        <div className="text-left">{details.description}</div>
      </div>
      <div className="mt-1 d-flex border-top align-items-start flex-column">
        {details.first_date === null ? (
          <div>Not yet awarded</div>
        ) : details.onetime ? (
          <div>Awarded: {details.first_date.substring(0, 10)}</div>
        ) : (
          <div className="d-flex align-items-start flex-column">
            <div>First awarded: {details.first_date.substring(0, 10)}</div>
            <div>Last awarded: {details.last_date.substring(0, 10)}</div>
            <div>
              Received {details.count} time{details.count > 1 ? "s" : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AchievementDetails;

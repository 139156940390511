import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AddTopicForm from "./AddTopicForm";

function TopicsDisplay({ topic, handleGoToTopic, setTopics }) {
  const [addTopicIsOpen, setAddTopicIsOpen] = useState(false);
  const addTopicHandleClose = () => {
    setAddTopicIsOpen(false);
  };

  const handleAddTopic = () => {
    setAddTopicIsOpen(true);
  };

  return (
    <>
      <Modal show={addTopicIsOpen} onHide={addTopicHandleClose}>
        <Modal.Body>
          <AddTopicForm
            parent_topic={topic}
            onCancel={addTopicHandleClose}
            setTopics={setTopics}
          />
        </Modal.Body>
      </Modal>
      <div className="subtopics m-2 d-flex flex-column">
        <div>
          <h1>Subtopics</h1>
        </div>
        {topic.sub_topics.map((topic) => {
          return (
            <div key={topic.id} className="d-flex justify-content-start m-1">
              <button
                type="button"
                className="btn btn-primary topic-button"
                onClick={() => handleGoToTopic(topic.id)}
              >
                {topic.name}
              </button>
            </div>
          );
        })}
        {topic.user_contributions ? (
          <div className="d-flex justify-content-start m-1">
            <button
              type="button"
              className="btn btn-secondary topic-button"
              onClick={() => handleAddTopic(topic)}
            >
              Add A Topic
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default TopicsDisplay;

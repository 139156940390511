import axios from "axios";
import React from "react";
import { USER_ENDPOINT } from "../../constants/urls";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function TopicsTestButtons({ topic, navigate }) {
  const userId = useSelector((state) => state.user.id);

  const createTestHandler = () => {
    axios
      .post(
        USER_ENDPOINT + userId + "/tests/",
        { topic: topic.id },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
          },
        }
      )
      .then((response) => {
        toast("Your test is ready");
        console.log("Going to /test/" + response.data["test"]["id"]);
        navigate("/test/" + response.data["test"]["id"]);
      })

      .catch((error) => {
        console.log(error);
        toast("There was an error creating your test");
      });
  };
  return (
    <>
      {topic.parent_topic.id === null ? null : (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            createTestHandler();
          }}
        >
          Take a test
        </button>
      )}
    </>
  );
}

export default TopicsTestButtons;

import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { RESET_PASSWORD_ENDPOINT } from "../../constants/urls";

function PasswordResetForm() {
  const resetValidation = yup.object({
    email: yup.string().email(),
  });

  const [resetSubmission, setResetSubmission] = useState(0);
  const handleSubmit = (values) => {
    axios
      .post(RESET_PASSWORD_ENDPOINT, values)
      .then((response) => {
        setResetSubmission(1);
      })
      .catch((error) => {
        setResetSubmission(-1);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const submitDisabled = () => {
    if (formik.values.email && formik.isValid) {
      return false;
    }
    return true;
  };

  if (resetSubmission === 1) {
    return <div>Check your email to reset your password</div>;
  } else if (resetSubmission === -1) {
    return (
      <div>
        There was an error resetting your password. Please try again later.
      </div>
    );
  } else {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Container>
          <Row>
            <Col className="d-inline-flex justify-content-center">
              <input
                className="form-control m-1"
                type="text"
                placeholder="Email"
                aria-label="email"
                {...formik.getFieldProps("email")}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-inline-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-info m-1"
                aria-label="reset password"
                disabled={submitDisabled()}
              >
                Reset Password
              </button>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

export default PasswordResetForm;

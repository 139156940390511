import React, { useEffect, useState } from "react";
import ListTests from "../../components/ListTests/ListTests";
import axios from "axios";
import { USER_ENDPOINT } from "../../constants/urls";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Tests() {
  const userId = useSelector((state) => state.user.id);
  const testListUrl = USER_ENDPOINT + userId + "/tests/";
  const [tests, setTests] = useState({ loaded: false, tests: null });
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      axios
        .get(testListUrl, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
          },
        })
        .then((response) => {
          console.log("Got response:");
          console.log(response.data);
          setTests({
            loaded: true,
            tests: response.data,
          });
        })
        .catch((error) => {
          setTests({
            loaded: false,
            tests: null,
          });
        });
    }
  }, [userId]);

  return (
    <div>
      <h1>Tests</h1>
      <div>
        <ListTests tests={tests.tests} navigate={navigate} />
      </div>
    </div>
  );
}

export default Tests;

import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import Legal from "./pages/Legal/Legal";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "./store/userSlice";
import { useEffect } from "react";
import Topics from "./pages/Topics/Topics";
import Layout from "./pages/Layout/Layout";
import Test from "./pages/Test/Test";
import Tests from "./pages/Tests/Tests";
import Achievements from "./pages/Achievements/Achievements";

export const routes = [
  { path: "legal", element: <Legal /> },
  { path: "verifyEmail/:verifySecret", element: <VerifyEmail /> },
  { path: "passwordReset", element: <PasswordReset /> },
  { path: "login", element: <Login /> },
  { path: "signup", element: <SignUp /> },
  {
    element: <Layout />,
    children: [
      { path: "tests/", element: <Tests /> },
      { path: "achievements/", element: <Achievements /> },
      { path: "topics/", element: <Topics /> },
      { path: "topics/:topicId", element: <Topics /> },
      { path: "test/:testId", element: <Test /> },
    ],
  },
  { path: "*", element: <Login /> },
];

export const publicRoutes = [
  { path: "legal", element: <Legal /> },
  { path: "verifyEmail/:verifySecret", element: <VerifyEmail /> },
];

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    dispatch(userActions.setJWT(JSON.parse(localStorage.getItem("JWT"))));
  }, []);

  return (
    <>
      <RouterProvider router={createBrowserRouter(routes)} />
      <ToastContainer />
    </>
  );
}

export default App;

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { REGISTRATION_ENDPOINT } from "../../constants/urls";

export const signupValidation = yup.object({
  name: yup.string().min(2),
  email: yup.string().email(),
  password: yup
    .string()
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\):;'",\.<>])(?=.{8,})/,
      "The password needs to have both uppercase and lowercase letters, at least one number, and punctuation"
    ),
  verifyPassword: yup.string().oneOf([yup.ref("password")]),
});

function SignUpForm() {
  const [regSubmission, setRegSubmission] = useState(0);
  const handleSubmit = (values) => {
    axios
      .post(REGISTRATION_ENDPOINT, values)
      .then((response) => {
        setRegSubmission(1);
      })
      .catch((error) => {
        setRegSubmission(-1);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      verifyPassword: "",
    },
    validationSchema: signupValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const submitDisabled = () => {
    if (
      formik.values.email &&
      formik.values.name &&
      formik.values.password &&
      formik.values.verifyPassword &&
      formik.isValid
    ) {
      return false;
    }
    return true;
  };

  if (regSubmission === 1) {
    return <div>Please check your inbox for an email.</div>;
  } else if (regSubmission === -1) {
    return (
      <div>
        There was an issue with your registration. Please try again later.
      </div>
    );
  } else {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Container>
          <Row>
            <Col className="d-inline-flex justify-content-center">
              <input
                className="form-control m-1"
                type="text"
                placeholder="Name"
                aria-label="name"
                {...formik.getFieldProps("name")}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-inline-flex justify-content-center">
              <input
                className="form-control m-1"
                type="text"
                placeholder="Email"
                aria-label="email"
                {...formik.getFieldProps("email")}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-inline-flex justify-content-center">
              <input
                className="form-control m-1"
                type="password"
                placeholder="Password"
                aria-label="password"
                {...formik.getFieldProps("password")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {formik.touched.password && formik.errors.password ? (
                <div className="error-msg">{formik.errors.password}</div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col className="d-inline-flex justify-content-center">
              <input
                className="form-control m-1"
                type="password"
                placeholder="Verify Password"
                aria-label="verify password"
                {...formik.getFieldProps("verifyPassword")}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center ">
            <Col className="d-inline-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-info m-1"
                aria-label="sign up"
                disabled={submitDisabled()}
              >
                Sign up
              </button>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

export default SignUpForm;

export const BACKEND_URL =
  "_env_" in window ? window._env_.BACKEND_URL : "http://localhost:8000";
/*
console.log("Here is the BACKEND_URL value:" + BACKEND_URL);
console.log("Here is the window value:");
console.log(window);
*/
export const REGISTRATION_ENDPOINT = BACKEND_URL + "/users/register/";
export const RESET_PASSWORD_ENDPOINT = BACKEND_URL + "/resetPassword/";
export const LOGIN_ENDPOINT = BACKEND_URL + "/users/token/";
export const VERIFY_EMAIL_ENDPOINT = BACKEND_URL + "/users/verifyEmail/";
export const GET_TOPIC_ENDPOINT = BACKEND_URL + "/quizlicious/topics/";
export const TOPIC_ENDPOINT = BACKEND_URL + "/quizlicious/topics/";
export const USER_ENDPOINT = BACKEND_URL + "/quizlicious/users/";
export const TESTS_ENDPOINT = BACKEND_URL + "/quizlicious/tests/";
export const USER_ACHIEVEMENTS_ENDPOINT = BACKEND_URL + "/achievements/users/";
export const ACHIEVEMENT_IMAGE_URL = "/images/achievements/";

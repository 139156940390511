import React, { useState } from "react";
import AchievementDetails from "../Achievement/AchievementDetails";

const Tooltip = (props) => {
  const [hideTooltip, setHideTooltip] = useState(true);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  console.log("props:");
  console.log(props);
  return (
    <div
      style={{
        fontSize: "12px",
        fontSizeAdjust: "0.25",
        overflow: "hidden",
        alignContent: "center",
        textAlign: "center",
      }}
      aria-describedby={`${props.id}.tooltip`}
      onClick={props.onClick}
      onMouseOver={(e) => {
        console.log("Determining tooltip location");
        var rect = e.target.getBoundingClientRect();
        console.log("Element location that mouse is over:");
        console.log(rect);
        var body = document.body;
        var docEl = document.documentElement;
        if (props.noOffset) {
          console.log("No offset");
          var scrollTop = 0;
          var scrollLeft = 0;
          var clientTop = 0;
          var clientLeft = 0;
        } else {
          console.log("Scroll offset");
          var scrollTop =
            window.pageYOffset || docEl.scrollTop || body.scrollTop;
          var scrollLeft =
            window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
          var clientTop = docEl.clientTop || body.clientTop || 0;
          var clientLeft = docEl.clientLeft || body.clientLeft || 0;
        }

        if (props.modalId) {
          console.log("Finding modal by id: " + props.modalId);
          var modalInfo = document.querySelector(`[id='${props.modalId}]`);
          var modalRect = modalInfo.getBoundingClientRect();
          var modalTop = modalInfo?.offsetTop ?? 0;
          var modalLeft = modalInfo?.offsetLeft ?? 0;
          var modalOffsetX = rect.right - modalRect.x;
          var modalOffsetY = rect.bottom - modalRect.y;
          var bottom = modalOffsetY;
          var right = modalOffsetX;
        } else {
          var bottom = rect.bottom + scrollTop - clientTop;
          var right = rect.right + scrollLeft - clientLeft;
        }

        console.log(
          "Location of tooltip will be at x=" + right + " and y=" + bottom
        );

        setMouseX(right);
        setMouseY(bottom);
        setHideTooltip(false);
      }}
      onMouseOut={(e) => {
        setHideTooltip(true);
      }}
    >
      <div>{props.children}</div>
      <div
        id={`${props.id}-tooltip`}
        hidden={hideTooltip}
        style={{
          backgroundColor: "#d9d9d9",
          boxSizing: "border-box",
          color: "#fff",
          display: "block",
          position: "absolute",
          top: `${mouseY}px`,
          left: `${mouseX}px`,
          zIndex: 1500,
          width: "300px",
          borderRadius: "5px",
        }}
        x-placement="bottom"
      >
        <div
          style={{
            backgroundColor: "#616161",
            opacity: 1,
            transform: "none",
            transition:
              "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            borderRadius: "5px",
            padding: "8px",
          }}
        >
          <AchievementDetails details={props.details} />
        </div>
      </div>
    </div>
  );
};

export default Tooltip;

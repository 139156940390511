import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import PasswordResetForm from "../../components/PasswordReset/PasswordResetForm";
import Logo from "../../assets/images/quizlicious-medium.jpg";

function PasswordReset() {
  return (
    <div className="login-page site-bg">
      <div className="login-container">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <img src={Logo} alt="Quizlicious" />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              Need help logging in? <br />
              Use this form to reset your password.
            </Col>
          </Row>
          <PasswordResetForm />
          <Row>
            <Col>
              <Link to="/" className="text-light">
                Already have an account? Login here!
              </Link>
            </Col>

            <Col>
              <Link to="/signup" className="text-light">
                Sign up for an account!
              </Link>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-center">
              <Link to="/legal" className="text-light">
                The Legal Stuff
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default PasswordReset;

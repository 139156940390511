import React from "react";

function TopicsNavButtons({ topic, handleGoToTopic }) {
  if (topic.id === null || topic.id === undefined) {
    return <button hidden={true}>Null</button>;
  } else if (topic.parent_topic.id) {
    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => handleGoToTopic(topic.parent_topic.id)}
      >
        Back to {topic.parent_topic.name}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => handleGoToTopic()}
      >
        Back to all topics
      </button>
    );
  }
}

export default TopicsNavButtons;

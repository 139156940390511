import React, { useEffect, useState } from "react";
import DisplayTestQuestion from "../../components/DisplayTestQuestion/DisplayTestQuestion";
import { TESTS_ENDPOINT } from "../../constants/urls";
import axios from "axios";
import { useParams } from "react-router-dom";

function Test() {
  let { testId } = useParams();
  const [question, setQuestion] = useState();

  useEffect(() => {
    axios
      .get(TESTS_ENDPOINT + testId + "/question/", {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
        },
      })
      .then((response) => {
        console.log("Got response:");
        console.log(response.data);
        setQuestion(response.data["question"]);
      })
      .catch((error) => {
        console.log("Got error:" + error.message);
        //console.log(error);
      });
  }, []);

  const submitHandler = (selected) => {
    console.log("submit answer handler called!");
    console.log(selected);
    console.log("Answer Id:");
    console.log(question.answers[selected].id);
    axios
      .post(
        TESTS_ENDPOINT + testId + "/question/",
        { answers: [question.answers[selected].id] },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
          },
        }
      )
      .then((response) => {
        console.log("Got response:");
        console.log(response.data);
        setQuestion(response.data["question"]);
      })
      .catch((error) => {});
  };

  console.log("Question:");
  console.log(question);
  if (question?.id) {
    return (
      <div>
        <h1>Test</h1>
        <DisplayTestQuestion
          question={question}
          submitHandler={submitHandler}
        />
      </div>
    );
  } else {
    return (
      <div>
        <h1>Test</h1>
        You have completed the test
      </div>
    );
  }
}

export default Test;

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import axios from "axios";
import { GET_TOPIC_ENDPOINT } from "../../constants/urls";
import { toast } from "react-toastify";

function AddTopicForm({ parent_topic, onCancel, setTopics }) {
  const handleSubmit = (values) => {
    axios
      .post(GET_TOPIC_ENDPOINT, values, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
        },
      })
      .then((response) => {
        toast("New topic added");
        setTopics({
          loaded: true,
          topics: response.data,
        });
        onCancel();
      })
      .catch((error) => {
        toast("Unable to add new topic");
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      parent_id: parent_topic.id,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const submitDisabled = () => {
    if (formik.values.name && formik.values.description && formik.isValid) {
      return false;
    }

    return true;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Container>
        <Row>
          <Col className="d-inline-flex justify-content-center">
            <div>Add a new topic into "{parent_topic?.name}"</div>
          </Col>
        </Row>

        <Row>
          <Col className="d-inline-flex justify-content-center">
            <input
              className="form-control m-1"
              type="text"
              placeholder="Name"
              aria-label="name"
              {...formik.getFieldProps("name")}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-inline-flex justify-content-center">
            <input
              className="form-control m-1"
              type="text"
              placeholder="Description"
              aria-label="description"
              {...formik.getFieldProps("description")}
            />
          </Col>
        </Row>

        <Row className="d-flex justify-content-center ">
          <Col className="d-inline-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-primary m-1"
              aria-label="save"
              disabled={submitDisabled()}
            >
              Save
            </button>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center ">
          <Col className="d-inline-flex justify-content-center">
            <button
              type="button"
              className="btn btn-primary m-1"
              aria-label="cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Container>
    </form>
  );
}

export default AddTopicForm;

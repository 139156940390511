import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../store/userSlice";

function UserIcon() {
  const userName = useSelector((state) => state.user.name);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutHandler = () => {
    localStorage.removeItem("JWT");
    dispatch(userActions.logout());
    navigate("/");
  };

  const topicsHandler = () => {
    navigate("/topics/");
  };

  const testsHandler = () => {
    navigate("/tests/");
  };

  const achievementsHandler = () => {
    navigate("/achievements/");
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="Primary" id="user-menu">
        <i
          className="bi bi-person-vcard-fill"
          style={{
            fontSize: "48px",
            color: "white",
            marginBottom: 0,
            paddingBottom: 0,
            marginTop: 0,
            paddingTopmarginTop: 0,
          }}
          aria-label="User Menu"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="align-items-end justify-content-end">
        <Dropdown.Item onClick={topicsHandler}>Topics</Dropdown.Item>
        <Dropdown.Item onClick={testsHandler}>Tests</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>{userName}</Dropdown.Header>
        <Dropdown.Item>My Profile</Dropdown.Item>
        <Dropdown.Item onClick={achievementsHandler}>
          Achievements
        </Dropdown.Item>
        <Dropdown.Item onClick={logoutHandler}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserIcon;

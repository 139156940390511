import axios from "axios";
import React, { useEffect, useState } from "react";
import { USER_ACHIEVEMENTS_ENDPOINT } from "../../constants/urls";
import UserAchievementsList from "../../components/Achievement/UserAchievementsList";
import { useSelector } from "react-redux";

function Achievements() {
  const userId = useSelector((state) => state.user.id);
  const [achievements, setAchievements] = useState({
    loaded: false,
    achievements: [],
  });
  useEffect(() => {
    if (userId !== null && userId !== undefined) {
      console.log(
        "Sending request to " +
          USER_ACHIEVEMENTS_ENDPOINT +
          userId +
          "/achievements/"
      );
      axios
        .get(USER_ACHIEVEMENTS_ENDPOINT + userId + "/achievements/", {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
          },
        })
        .then((response) => {
          console.log("Got response for achievements:");
          console.log(response.data);
          setAchievements({
            loaded: true,
            achievements: response.data["achievements"],
            categories: response.data["categories"],
          });
        })
        .catch((error) => {
          console.log("Error getting achievements:");
          console.log(error.message);
        });
    }
  }, [userId]);

  return (
    <div style={{ height: "100vh" }}>
      <h1>Achievements</h1>
      {achievements.loaded ? (
        <UserAchievementsList achievements={achievements} />
      ) : (
        "Loading"
      )}
    </div>
  );
}

export default Achievements;

import React, { useEffect } from "react";
import { Outlet } from "react-router";
import Header from "../../components/Header/Header";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

function Layout() {
  const navigate = useNavigate();
  useEffect(() => {
    //console.log("Checking JWT..." + localStorage.JWT);
    if (localStorage.JWT === undefined) {
      console.log("The authorization has expired. Redirecting to login");
      navigate("/login");
    }
    const accessTokenDecoded = jwt_decode(
      JSON.parse(localStorage.getItem("JWT")).access
    );
    //console.log("Current date:" + Date.now() / 1000);
    //console.log("JWT expiring:" + accessTokenDecoded.exp);
    if (accessTokenDecoded.exp < Date.now() / 1000) {
      //console.log("The authorization has expired. Redirecting to login");
      navigate("/login");
    }
  }, []);

  if (localStorage.JWT !== undefined) {
    return (
      <section className="position-relative vh-100 site-bg">
        <Header />
        <div className="d-flex justify-content-between">
          <div className="px-3 pe-lg-5 ps-sm-5 ps-md-0 ps-lg-5 ps-xxl-3 main_content d-flex flex-column flex-md-row justify-content-between pt-4 w-100 mt-xxl-1">
            <div
              className="workarea order-2 order-md-1 pt-sm-4 w-100 ps-xxl-5 position-relative "
              id="workarea"
              data-testid="workarea"
            >
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Layout;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { VERIFY_EMAIL_ENDPOINT } from "../../constants/urls";

function VerifyEmailResults({ verifySecret }) {
  const [emailVerified, setEmailVerified] = useState();

  useEffect(() => {
    axios
      .post(VERIFY_EMAIL_ENDPOINT, { verification_secret: verifySecret })
      .then((response) => {
        setEmailVerified(true);
      })
      .catch((error) => {
        setEmailVerified(false);
      });
  }, [verifySecret]);

  if (emailVerified === undefined) {
    return <div>Loading</div>;
  } else if (emailVerified) {
    return <div>We have verified your email</div>;
  } else {
    return <div>We could not verify your email</div>;
  }
}

export default VerifyEmailResults;

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik, Form, Field, FieldArray } from "formik";
import { TOPIC_ENDPOINT } from "../../constants/urls";
import axios from "axios";
import { toast } from "react-toastify";
import * as yup from "yup";

function AddQuestionForm({ topic, onCancel, setQuestions }) {
  const questionValidation = yup.object({
    question: yup.string(),
    type: yup.number(),
    reference_url: yup.string(),
    reference_book: yup.string(),
    reference_book_pub_date: yup.string(),
    reference_book_author: yup.string(),
    reference_book_publisher: yup.string(),
  });

  const handleSubmit = (values) => {
    console.log("handle submit");
    console.log("URL: " + TOPIC_ENDPOINT + topic.id + "/questions/");
    values.type = parseInt(values.type);
    axios
      .post(TOPIC_ENDPOINT + topic.id + "/questions/", values, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.JWT).access}`,
        },
      })
      .then((response) => {
        toast("New question added");
        onCancel();
        setQuestions({
          loaded: true,
          questions: response.data,
        });
      })
      .catch((error) => {
        toast("Unable to add new question");
      });
  };

  let initialQuestionValues = {
    question: "",
    type: "",
    reference_url: "",
    reference_book: "",
    reference_book_pub_date: "",
    reference_book_author: "",
    reference_book_publisher: "",
    correct_answers: [""],
    incorrect_answers: [""],
    match: [{ a: "", b: "" }],
  };

  const goodAnswerName = (e) => {
    //console.log("received e", e);
    if (e === "5") {
      return "True Statement";
    } else if (e === "3" || e === "4") {
      return "Item";
    } else {
      return "Correct Answer";
    }
  };

  const goodFieldName = (e) => {
    //console.log("received e", e);
    if (e === "3") {
      return "match_a";
    } else {
      return "correct_answers";
    }
  };

  const badAnswerName = (e) => {
    //console.log("received e", e);
    if (e === "5") {
      return "False Statement";
    } else {
      return "Incorrect Answer";
    }
  };

  return (
    <Formik
      initialValues={initialQuestionValues}
      onSubmit={(values) => {
        console.log("Submit!");
        handleSubmit(values);
      }}
    >
      {({ values, isValid }) => {
        const submitDisabled = () => {
          /*
          console.log("Checking if submit is still disabled:");
          console.log(values);
          console.log(isValid);
          */
          if (!values.type) {
            return true;
          }
          if (
            values.type === "1" &&
            values.question &&
            values.correct_answers.length > 0 &&
            values.correct_answers.every((a) => a.length > 0) &&
            values.incorrect_answers.length > 0 &&
            values.incorrect_answers.every((a) => a.length > 0) &&
            isValid
          ) {
            return false;
          }
          if (
            values.type === "2" &&
            values.question &&
            isValid &&
            ((values.correct_answers.length > 0 &&
              values.correct_answers.every((a) => a.length > 0)) ||
              (values.incorrect_answers.length > 0 &&
                values.incorrect_answers.every((a) => a.length > 0)))
          ) {
            return false;
          }
          if (
            values.type === "5" &&
            isValid &&
            ((values.correct_answers.length > 0 &&
              values.correct_answers.every((a) => a.length > 0)) ||
              (values.incorrect_answers.length > 0 &&
                values.incorrect_answers.every((a) => a.length > 0)))
          ) {
            return false;
          }

          if (
            values.type === "6" &&
            values.question &&
            isValid &&
            values.correct_answers.length > 0 &&
            values.correct_answers.every((a) => a.length > 0)
          ) {
            return false;
          }
          console.log("Values:");
          console.log(values);

          // For Order questions
          if (
            values.type === "4" &&
            values.question &&
            isValid &&
            values.correct_answers.length > 1 &&
            values.correct_answers.every((a) => a.length > 0)
          ) {
            return false;
          }

          // For Match questions
          if (
            values.type === "3" &&
            values.question &&
            isValid &&
            values.match.length > 1 &&
            values.match.every((m) => m.a.length > 0) &&
            values.match.every((m) => m.b.length > 0)
          ) {
            return false;
          }

          return true;
        };

        return (
          <Form>
            <Container>
              <Row>
                <Col className="d-inline-flex justify-content-center">
                  Add a question to "{topic.name}"
                </Col>
              </Row>
              <Row>
                <Col className="d-inline-flex justify-content-start">
                  <Field
                    name="type"
                    as="select"
                    aria-label="Question Type"
                    className="form-control"
                  >
                    <option value="">Select a question type</option>
                    <option value="1">Multiple choice</option>
                    <option value="2">Choose all that apply</option>
                    <option value="3">Match the items</option>
                    <option value="4">Order the items</option>
                    <option value="5">True or false</option>
                    <option value="6">Acronym</option>
                  </Field>
                </Col>
              </Row>

              {values.type ? (
                <div>
                  <Row className="mt-2">
                    <Col className="d-inline-flex justify-content-start">
                      {values.type === "5" ? null : (
                        <Field
                          name="question"
                          type="text"
                          placeholder={
                            values.type === "6" ? "Acronym" : "Question"
                          }
                          aria-label={
                            values.type === "6" ? "Acronym" : "Question"
                          }
                          className="form-control"
                        />
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="d-inline-flex justify-content-center">
                      {`${goodAnswerName(values.type)}s`}
                    </Col>
                  </Row>
                  {values.type === "3" ? (
                    <>
                      <FieldArray
                        name="match"
                        render={(arrayHelpers) => (
                          <>
                            {values.match && values.match.length > 0
                              ? values.match.map(({}, index) => (
                                  <Row key={`match${index}`}>
                                    <Col>
                                      <div className="d-flex no-wrap">
                                        <Field
                                          name={`match[${index}].a`}
                                          placeholder={`Item ${index + 1}`}
                                          aria-label={`Item ${index + 1}`}
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="d-flex no-wrap">
                                        <Field
                                          name={`match[${index}].b`}
                                          placeholder={`Match ${index + 1}`}
                                          aria-label={`Match ${index + 1}`}
                                          className="form-control"
                                        />
                                        <button
                                          type="button"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                          }}
                                        >
                                          x
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              : null}
                            <Row className="mt-2">
                              <Col className="d-inline-flex justify-content-center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.push({ a: "", b: "" });
                                  }}
                                  aria-label={`Add Another ${goodAnswerName(
                                    values.type
                                  )}`}
                                >
                                  {`Add Another ${goodAnswerName(values.type)}`}
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <FieldArray
                        name="correct_answers"
                        render={(arrayHelpers) => (
                          <>
                            {values.correct_answers &&
                            values.correct_answers.length > 0
                              ? values.correct_answers.map(
                                  (correct_answer, index) => (
                                    <Row key={`correct${index}`}>
                                      <Col>
                                        <div className="d-flex no-wrap">
                                          <Field
                                            name={`${goodFieldName(
                                              values.type
                                            )}.${index}`}
                                            placeholder={`${goodAnswerName(
                                              values.type
                                            )} ${index + 1}`}
                                            aria-label={`${goodAnswerName(
                                              values.type
                                            )} ${index + 1}`}
                                            className="form-control"
                                          />
                                          {values.type === "3" ? null : (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}
                                            >
                                              x
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                      {values.type === "3" ? (
                                        <Col>
                                          <div className="d-flex no-wrap">
                                            <Field
                                              name={`match_b.${index}`}
                                              placeholder={`Match ${index + 1}`}
                                              aria-label={`Match ${index + 1}`}
                                              className="form-control"
                                            />
                                            <button
                                              type="button"
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}
                                            >
                                              x
                                            </button>
                                          </div>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  )
                                )
                              : null}
                            <Row className="mt-2">
                              <Col className="d-inline-flex justify-content-center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.push("");
                                  }}
                                  aria-label={`Add Another ${goodAnswerName(
                                    values.type
                                  )}`}
                                >
                                  {`Add Another ${goodAnswerName(values.type)}`}
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                      />
                    </>
                  )}

                  {values.type === "6" ||
                  values.type === "4" ||
                  values.type === "3" ? null : (
                    <>
                      <Row className="mt-4">
                        <Col className="d-inline-flex justify-content-center">
                          {`${badAnswerName(values.type)}s`}
                        </Col>
                      </Row>
                      <FieldArray
                        name="incorrect_answers"
                        render={(arrayHelpers) => (
                          <div>
                            {values.incorrect_answers &&
                            values.incorrect_answers.length > 0
                              ? values.incorrect_answers.map(
                                  (incorrect_answer, index) => (
                                    <Row key={`incorrect${index}`}>
                                      <Col>
                                        <div className="d-flex no-wrap">
                                          <Field
                                            name={`incorrect_answers.${index}`}
                                            placeholder={`${badAnswerName(
                                              values.type
                                            )} ${index + 1}`}
                                            aria-label={`${badAnswerName(
                                              values.type
                                            )} ${index + 1}`}
                                            className="form-control"
                                          />
                                          <button
                                            type="button"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            x
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  )
                                )
                              : null}
                            <Row className="mt-2">
                              <Col className="d-inline-flex justify-content-center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.push("");
                                  }}
                                  aria-label={`Add Another ${badAnswerName(
                                    values.type
                                  )}`}
                                >
                                  {`Add Another ${badAnswerName(values.type)}`}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      />{" "}
                    </>
                  )}
                </div>
              ) : null}

              <div className="accordion mt-4" id="accordionReferences">
                <div className="accordion-item">
                  <Row className="mt-4">
                    <Col className="d-inline-flex justify-content-center">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          References
                        </button>
                      </h2>
                    </Col>
                  </Row>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionReferences"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col className="d-inline-flex justify-content-start">
                          <Field
                            name="reference_url"
                            type="text"
                            placeholder="Reference URL"
                            aria-label="Reference URL"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-inline-flex justify-content-start">
                          <Field
                            name="reference_book"
                            type="text"
                            placeholder="Reference Book"
                            aria-label="Reference Book"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-inline-flex justify-content-start">
                          <Field
                            name="reference_book_author"
                            type="text"
                            placeholder="Reference Book Authors"
                            aria-label="Reference Book Authors"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-inline-flex justify-content-start">
                          <Field
                            name="reference_book_publisher"
                            type="text"
                            placeholder="Reference Book Publisher"
                            aria-label="Reference Book Publisher"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-inline-flex justify-content-start">
                          <label>
                            Reference Book Publishing Date
                            <Field name="reference_book_pub_date" type="text" />
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              <Row className="d-flex justify-content-start">
                <Col className="d-inline-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary m-1"
                    aria-label="save"
                    disabled={submitDisabled()}
                  >
                    Save
                  </button>
                </Col>
              </Row>

              <Row className="d-flex justify-content-start">
                <Col className="d-inline-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary m-1"
                    aria-label="cancel"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AddQuestionForm;

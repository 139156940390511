import React, { useState } from "react";
import { ACHIEVEMENT_IMAGE_URL } from "../../constants/urls";
import Tooltip from "../Tooltip/Tooltip";
import { Modal } from "react-bootstrap";

function AchievementIcon({ details }) {
  return (
    <div>
      <Tooltip details={details} id={`achievement-${details.id}`}>
        <div className="m-2">
          <img
            className="rounded"
            src={
              details.count > 0
                ? ACHIEVEMENT_IMAGE_URL + details.filename + ".awarded.png"
                : ACHIEVEMENT_IMAGE_URL + details.filename + ".png"
            }
            alt={details.name}
          />
          {details.onetime ? null : details.count > 1 ? (
            <div className="text-right">x{details.count}</div>
          ) : null}
        </div>
      </Tooltip>
    </div>
  );
}

export default AchievementIcon;

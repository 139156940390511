import React, { useState } from "react";
import clsx from "clsx";

function DisplayTestQuestion({ question, submitHandler }) {
  const [selected, setSelected] = useState();
  /*
  console.log("Question data:");
  console.log(question);
  console.log("Selected answer");
  console.log(selected);
  */

  const handleAnswerClick = (index) => {
    setSelected(index);
  };

  if (question !== undefined) {
    return (
      <div>
        <div>
          <h2>Question</h2>
        </div>
        <div className="border rounded px-2 py-1 m-1 bg-light text-dark">
          {question.question}
        </div>
        <div>
          <h2>Answers</h2>
        </div>
        {question.answers.map((answer, index) => {
          return (
            <div key={index}>
              <button
                type="button"
                data-testid={index === selected ? "selected-answer" : ""}
                className={clsx(
                  "border rounded px-2 py-1 m-1",
                  index === selected
                    ? "bg-info text-dark"
                    : "bg-light text-dark"
                )}
                onClick={() => {
                  handleAnswerClick(index);
                }}
              >
                {answer.answer}
              </button>
            </div>
          );
        })}
        <div className="d-flex justify-content-center m-1">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={() => {
              //console.log("Submitting the answer:");
              //console.log(selected);
              setSelected();
              submitHandler(selected);
            }}
          >
            Submit Answer
          </button>
        </div>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}

export default DisplayTestQuestion;

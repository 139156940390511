import React from "react";
import { useNavigate } from "react-router-dom";

function Legal() {
  const navigate = useNavigate();

  return (
    <div className="p-4">
      <div>
        <button
          type="button"
          className="btn btn-primary m-1"
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </button>
      </div>
      <h1 className="text-center">The Legal Stuff</h1>
    </div>
  );
}

export default Legal;

import React, { useState } from "react";
import AchievementIcon from "./AchievementIcon";

function UserAchievementsList({ achievements }) {
  const categories = [{ id: null, name: null }];
  categories.push(...achievements.categories);

  return (
    <div className="m-2">
      {categories?.map((category, index) => {
        return (
          <div key={index} className="mt-2 border-top">
            {category.name}
            <div className="d-flex flex-wrap m-2">
              {achievements.achievements?.map((achievement, index) => {
                if (achievement.category === category.id) {
                  return (
                    <div key={index}>
                      <AchievementIcon details={achievement} />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default UserAchievementsList;

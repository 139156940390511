import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import "bootstrap-icons/font/bootstrap-icons.css";

const store = configureStore({
  reducer: {
    user: userReducer,
  },
});

export default store;
